import * as React from "react";
import InfoCircleIcon from "mdi-react/InformationOutlineIcon";
import InfoIcon from "mdi-react/InformationVariantIcon";
import EmailIcon from "mdi-react/EmailMultipleIcon";
import * as ReactDOMClient from 'react-dom/client';
import OkIcon from "mdi-react/CheckBoldIcon";
import AlerIcon from "mdi-react/AlertIcon";
import log from "services/log";

/* eslint jsx-a11y/anchor-is-valid: 0 */

/**
 * Componente que construye y muestra una alerta en el DOM.
 * Este componente no es público, se espera que sólo lo llame la función `Alert`. 
 * 
 * @param {ReactDOMClient.Root} modal - Elemento raíz donde se renderizará la alerta.
 * @param {String} msg - Mensaje a mostrar en la alerta.
 * @param {String} icon - Icono de la alerta `alert`, `warning`, `ok`, `info_circle`, `info`
 * @param {Function} action - Función a ejecutar cuando se interactúa con la alerta `callback`
 */
const AlertComponent = ({ modal, msg, icono, action }) => {
    let Icon;

    const onDismiss = () => {
        action();
        document.body.style.overflow = "auto";
        modal.unmount();
    }

    switch (icono) {
        case "alert":
        case "warning":
            Icon = <AlerIcon className="red" />;
            break;
        case "ok":
            Icon = <OkIcon className="green" />;
            break;
        case "infoc":
            Icon = <InfoCircleIcon />;
            break;
        case "info":
        default:
            Icon = <InfoIcon />;
            break;
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        if (navigator.vibrate) {
            navigator.vibrate([150, 50, 150, 50, 200]);
        } else {
            log.e('El vibrador no esta disponible en este navegador');
        }
    }, []);

    return (
        <div className="alert__content">
            <div className="alert__icon">{Icon}</div>
            <p>{msg}</p>
            <a className="close" onClick={onDismiss}>Aceptar</a>
        </div>
    );
}

const ConfirmComponent = ({ modal, msg, action }) => {
    let Icon = <AlerIcon className="yellow" />;

    const onDismiss = (e, _response) => {
        e.preventDefault();
        e.stopPropagation();

        action(_response);
        document.body.style.overflow = "auto";
        modal.unmount();
    }

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        if (navigator.vibrate) {
            navigator.vibrate([150, 50, 150, 50, 200]);
        } else {
            log.e('El vibrador no esta disponible en este navegador');
        }
    }, []);

    return (
        <div className="alert__content">
            <div className="alert__icon">{Icon}</div>
            <p>{msg}</p>
            <div className="in_row">
                <a className="close" onClick={e => onDismiss(e, true)}>Aceptar</a>
                <a className="close" onClick={e => onDismiss(e, false)}>Cancelar</a>
            </div>
        </div>
    );
}


const AgregaCorreoComponent = ({ modal, msg, correo, action }) => {
    const [correos, setCorreos] = React.useState("");

    const onDismiss = (e, _response) => {
        e.preventDefault();
        e.stopPropagation();
        let _correos = correos.trim();

        if (_correos.startsWith(",")) {
            _correos = _correos.substring(1);
        }

        action(_response, _correos);
        document.body.style.overflow = "auto";
        modal.unmount();
    }

    return (
        <div className="alert__content">
            <p style={{ height: 24 }}></p>
            <p>{msg}</p>
            <div style={{ margin: "0 12px" }}>
                <label>Correo(s)</label>
                <div className="input_icon">
                    <input type="email" name="correo" className="to_mail" placeholder={correo}
                        value={correos} onChange={e => setCorreos(e.target.value)} />
                    <EmailIcon />
                </div>
            </div>

            <div className="in_row">
                <a className="close" onClick={e => onDismiss(e, true)}>Aceptar</a>
                <a className="close" onClick={e => onDismiss(e, false)}>Cancelar</a>
            </div>
        </div>
    );
}

/**
 * Muestra una alerta de tipo popup
 * 
 * @param {String} msg - Mensaje a mostrar en la alerta.
 * @param {String} icon - Icono de la alerta `alert`, `warning`, `ok`, `info_circle`, `info`
 * @param {Function} action - Función a ejecutar cuando se interactúa con la alerta `callback`
 */
const Alert = (_msg, _icono = "info", action = () => { }) => {
    const _modal = ReactDOMClient.createRoot(document.getElementById("modal"));

    _modal.render(
        <div className="alert">
            <AlertComponent modal={_modal} msg={_msg} icono={_icono} action={action} />
        </div>);
}

/**
 * Muestra una ventana de confirmación
 * @param {String} _msg Mensaje a mostrar
 * @param {Function} action función de respuesta callback
 */
const Confirm = (_msg, action = () => { }) => {
    const _modal = ReactDOMClient.createRoot(document.getElementById("modal"));

    _modal.render(
        <div className="alert">
            <ConfirmComponent modal={_modal} msg={_msg} action={action} />
        </div>);
}

const alertArea = (_current_node, _msg) => {
    let _new_node = document.createElement("span");
    let _add_alert = true;

    if (_current_node.classList.contains("alert__resalta_input")) {
        _add_alert = false;
    }

    if (_add_alert) {
        _current_node.classList.add("alert__resalta_input");
        _new_node.className = "alert__input _up _area";
        _new_node.innerText = _msg;

        _current_node.insertAdjacentElement("afterend", _new_node);
    }
}

/**
 * Muestra una alerta de tipo bocadillo para señalar un error en un formulario.
 * Se mantiene el nombre de la función en español para evitar conflictos con la función de alerta de JavaScript.
 * 
 * @param {HTMLElement} current_node - Nodo del DOM donde se mostrará la alerta.
 * @param {String} msg - Mensaje a mostrar en la alerta.
 */
const alerta = (_current_node, _msg) => {
    let _new_node = document.createElement("span");
    let _add_alert = true;

    if (_current_node.classList.contains("alert__resalta_input")) {
        _add_alert = false;
    }

    if (_add_alert) {
        _current_node.classList.add("alert__resalta_input");
        _new_node.className = "alert__input _up";
        _new_node.innerText = _msg;

        _current_node.insertAdjacentElement("afterend", _new_node);
    }
}

const alertDown = (_current_node, _msg) => {
    let _new_node = document.createElement("span");
    let _add_alert = true;

    if (_current_node.classList.contains("alert__resalta_input")) {
        _add_alert = false;
    }

    if (_add_alert) {
        _current_node.classList.add("alert__resalta_input");
        _new_node.className = "alert__input _down";
        _new_node.innerText = _msg;

        _current_node.insertAdjacentElement("afterend", _new_node);
    }
}

/**
 * Función que elimina una alerta de tipo bocadillo de un nodo específico del DOM.
 * 
 * @param {HTMLElement} current_node - Nodo del DOM del cual se eliminará la alerta.
 */
const alertaRemove = (_current_node) => {
    let _alerta = _current_node == null ? null : _current_node.nextSibling;

    if (_alerta != null) {
        _current_node.classList.remove("alert__resalta_input");

        if (_alerta.className === "alert__input _up"
            || _alerta.className === "alert__input _down"
            || _alerta.className === "alert__input _up _area") {
            _alerta.parentNode.removeChild(_alerta);
        }
    }
}

const AgregaCorreo = (_msg, _correo, action = () => console.log("No action defined")) => {
    const _modal = ReactDOMClient.createRoot(document.getElementById("modal"));

    _modal.render(
        <div className="alert">
            <AgregaCorreoComponent modal={_modal} msg={_msg} correo={_correo} action={action} />
        </div>);
}

export { Alert, Confirm, alerta, alertArea, alertDown, alertaRemove, AgregaCorreo };
