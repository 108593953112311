import Portafolio from "../../Portafolio/index";
import withRouter from "services/withRouter";
import * as React from "react";
import useMedia from "hooks";


const Portfolio = (router) => {
    const { t, telegram, setTelegram } = useMedia();
    const { navigate } = router;

    React.useEffect(() => {
        document.title = t.menu.portafolio;
        setTelegram(navigate);
        if (telegram) {
            telegram.BackButton.show();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="content_app">
            <div className="header path_right">
                <h1>{t.menu.portafolio}</h1>
            </div>
            <Portafolio />
        </div>
    );
}

export default withRouter(Portfolio);