import * as React from 'react';
import init, { Cambas } from "./components/inicio";
import Portafolio from '../Portafolio';
import Contacto from '../Contacto';
import useMedia from 'hooks';


const Inicio = (params) => {
    const { t } = useMedia();

    React.useEffect(() => {
        document.title = t.home.dando_vida;
        init(t);
    }, [t]);

    return (
        <div className="cont_principal" id="cont_principal">

            <Cambas {...params} />

            <h1 id="h1_saludo">{t.home.hi}<br />{t.home.welcome}</h1>

            <Portafolio />
            <Contacto />

        </div>
    );
}

export default Inicio;