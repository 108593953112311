import * as React from 'react';
import web_productores_a_mi from "assets/imagenes/web_productores_a_mi.png";
import web_tobiaextreme from "assets/imagenes/web_tobiaextreme.png";
import web_sweetwater from "assets/imagenes/web_sweetwater.png";
import web_tuserenata from "assets/imagenes/web_tuserenata.png";
import web_servixion from "assets/imagenes/web_servixion.png";
import web_miparque from "assets/imagenes/web_miparque.png";
import web_mibus from "assets/imagenes/web_mibus.png";
import web_biori from "assets/imagenes/web_biori.png";
import web_bts from "assets/imagenes/web_bts.png";
import cell_pcc from "assets/imagenes/cell_pcc.png";
import cell_bts from "assets/imagenes/cell_bts.png";
import cell_jobu from "assets/imagenes/cell_jobu.png";
import cell_mibus from "assets/imagenes/cell_mibus.png";
import cell_sweetwater from "assets/imagenes/cell_sweetwater.png";
import cell_tuserenata from "assets/imagenes/cell_tuserenata.png";
import cell_tobiaextreme from "assets/imagenes/cell_tobiaextreme.png";
import cell_productores_a_mi from "assets/imagenes/cell_productores_a_mi.png";
// import marcocell from "assets/imagenes/marco_cell.png";
import useMedia from 'hooks';
import CloseIcon from "mdi-react/CloseIcon";
import MinusIcon from "mdi-react/MinusIcon";
import MenuIcon from "mdi-react/MenuIcon";
import MenuSwapIcon from "mdi-react/MenuSwapIcon";

const Portafolio = () => {
    const { t } = useMedia();

    const Navegador = ({ children, href }) => {

        return <div className="marco_web">
            <CloseIcon className="_close" />
            <MinusIcon className="_minus" />
            <MenuSwapIcon className="_swap" />
            <MenuIcon className="_menu" />
            <div className="_bar_dir">
                <a href={href} target="_blank" rel="noopener noreferrer">{href}</a>
            </div>

            {children}
        </div>;
    }

    const Aplication = ({ children }) => {

        return <div className="marco_web _pwa">
            <CloseIcon className="_close" />
            <MinusIcon className="_minus" />
            <MenuSwapIcon className="_swap" />

            {children}
        </div>;
    }

    return (
        <div className="cont_portafolio" id="portafolio">

            <div className="cont_proyect path_left _intro">
                <h1>{t.menu.portafolio}</h1>

                <p>{t.home.about_me}</p>
                <p>{t.home.below_portfolio}</p>
                <h3>
                    {t.home.servers} <span>{t.home.servers_cont}</span>
                    <br />
                    {t.home.design_and_development} <span>{t.home.design_and_development_cont}</span>
                    <br />
                    <br />
                </h3>
            </div>

            <div className="cont_proyect path_right">
                <h2>Best Transport Service </h2>
                <Aplication>
                    <img src={web_bts} alt="Best Transport Service App" className="img_web" />
                </Aplication>
                <img src={cell_bts} alt="Best Transport Service movil" className="img_cell" />
                <p>Es una aplicación de gestión empresarial para la empresa Best Transport Service, organiza las entregas del día a día manteniendo centralizada la información para sus posteriores descarga de informes, además gestiona los, mensajeros y coordinadores manteniendo informados a sus clientes.</p>
                <h4>
                    Trabajo <span>Se crea sistemas de gestión empresarial (ERP) como aplicación web</span>
                    <br />
                    Tipo <span>ERP, Web App, PWA</span>
                    <br />
                    Tecnología <span className="_tec">React JS, Node JS, LESS/SASS/SCSS, JavaScript</span>
                </h4>
            </div>

            <div className="cont_proyect path_left _only_app">
                <h2>App Conservadora</h2>

                <img src={cell_pcc} alt="App Conservadora movil" className="img_cell" />
                <p>Aplicación para el partido conservador colombiano con la cual gestiona a sus militantes y los mantiene informados publicando noticias, eventos y otras actividades concernientes al partido.</p>
                <h4>
                    Trabajo <span>Se crea aplicación móvil</span>
                    <br />
                    Tipo <span>App móvil</span>
                    <br />
                    Tecnología <span className="_tec">React Native, Node JS, Express, Android, IOS</span>
                </h4>
            </div>

            <div className="cont_proyect path_right">
                <h2>TobiaExtreme </h2>
                <Aplication href="https://tobiaextreme.com">
                    <img src={web_tobiaextreme} alt="TobiaExtreme web" className="img_web" />
                </Aplication>
                <img src={cell_tobiaextreme} alt="TobiaExtreme movil" className="img_cell" />
                <p>Sistema de gestión de ventas y agentes para tobiaextreme que es una empresa dedicada a las actividades turísticas extremas.</p>
                <h4>
                    Trabajo <span>Se crea sistemas de gestión empresarial (ERP) como aplicación web</span>
                    <br />
                    Tipo <span>ERP, Web App, PWA</span>
                    <br />
                    Tecnología <span className="_tec">React JS, Node JS, LESS/SASS/SCSS, JavaScript</span>
                </h4>
            </div>

            <div className="cont_proyect path_left">
                <h2>Mi Bus </h2>
                <Navegador href="https://mibus.com.bo">
                    <img src={web_mibus} alt="Mi Bus web" className="img_web" />
                </Navegador>
                <img src={cell_mibus} alt="Mi Bus movil" className="img_cell" />
                <p>Es una aplicación que ofrece el servicio de venta de pasajes online vía terrestre, facilitando el proceso de compra para todo viajero.</p>
                <p>Ofrece seguridad, comodidad, facilidad y rapidez para encontrar su pasaje vía terrestre, tanto en buses, micros y minibuses que se acomode a sus horarios y bolsillos.</p>
                <h4>
                    Trabajo <span>Se crea aplicación móvil y sitio web</span>
                    <br />
                    Tipo <span>Sito Web, App móvil</span>
                    <br />
                    Tecnología <span className="_tec">PHP, HTML, CSS, JavaScript, Java, Android</span>
                </h4>
            </div>

            <div className="cont_proyect path_right _only_web">
                <h2>Biori </h2>
                <Navegador href="https://biori.cl">
                    <img src={web_biori} alt="Biori web" className="img_web" />
                </Navegador>

                <h4>
                    Trabajo <span>Se agrega medio de pago con FLOW</span>
                    <br />
                    Tipo <span>Sito Web, Pagos electrónicos</span>
                    <br />
                    Tecnología <span>wordpress, PHP</span>
                </h4>
            </div>

            <div className="cont_proyect path_left _only_app">
                <h2>Jobu</h2>

                <img src={cell_jobu} alt="App Conservadora movil" className="img_cell" />
                <p>Aplicación para que los usuarios puedan encontrar la solución a sus problemas cotidianos o ayudar a otros a resolverlos y ganar un dinero extra haciendo lo que saben.</p>
                <h4>
                    Trabajo <span>Se crea aplicación móvil</span>
                    <br />
                    Tipo <span>App móvil</span>
                    <br />
                    Tecnología <span className="_tec">Android, IOS</span>
                </h4>
            </div>

            <div className="cont_proyect path_right _only_web">
                <h2>Servixion </h2>
                <Navegador href="https://servixion.com">
                    <img src={web_servixion} alt="Servixion web" className="img_web" />
                </Navegador>
                <p>Es una aplicación para que los comerciantes que quieren ofrecer sus servicios sin la necesidad de crear una aplicación propia, conectando de forma sencilla a con nuevos clientes, poniendo en sus manos las herramientas necesarias para gestionar su información y aplicarla a los diferentes criterios de búsqueda.</p>
                <h4>
                    Trabajo <span>Se crea landing page</span>
                    <br />
                    Tipo <span>Sito Web</span>
                    <br />
                    Tecnología <span>React JS, LESS/SASS/SCSS</span>
                </h4>
            </div>

            <div className="cont_proyect path_left _only_web">
                <h2>Mi Parque </h2>
                <Navegador href="https://miparque.cl">
                    <img src={web_miparque} alt="Mi Parque web" className="img_web" />
                </Navegador>

                <h4>
                    Trabajo <span>Se crea script para gestionar pagos con FLOW</span>
                    <br />
                    Tipo <span>Sito Web, Pagos electrónicos</span>
                    <br />
                    Tecnología <span>Wordpress, PHP</span>
                </h4>
            </div>

            <div className="cont_proyect path_right">
                <h2>Sweet Water</h2>
                <Navegador href="https://sweetwatersai.com">
                    <img src={web_sweetwater} alt="Sweet Water web" className="img_web" />
                </Navegador>
                <img src={cell_sweetwater} alt="Sweet Water movil" className="img_cell" />
                <p>Ofrece un agua tratada con los más altos parámetros de calidad, con el uso de las tapas verdes biodegradables que con su sellado y termofijado  garantiza su excelente  conservacion.</p>
                <h4>
                    Trabajo <span>Se crea aplicación móvil y sitio web</span>
                    <br />
                    Tipo <span>Sito Web, App móvil</span>
                    <br />
                    Tecnología <span className="_tec">PHP, HTML, CSS, JavaScript, Java, Android</span>
                </h4>
            </div>
            
            <div className="cont_proyect path_left">
                <h2>Productores a mi </h2>
                <Navegador href="https://productoresami.com">
                    <img src={web_productores_a_mi} alt="Productores a mi web" className="img_web" />
                </Navegador>
                <img src={cell_productores_a_mi} alt="Productores a mi movil" className="img_cell" />
                <p>Es un marketplace B2B que distribuye diferentes productos e insumos directamente del productor (sin intermediarios) a tú negocio, Restaurant, Almacén, Sushi, etc (canal horeca)</p>
                <h4>
                    Trabajo <span>Se crea aplicación móvil y sitio web</span>
                    <br />
                    Tipo <span>Sito Web, App móvil</span>
                    <br />
                    Tecnología <span className="_tec">PHP, HTML, CSS, JavaScript, Java, Android</span>
                </h4>
            </div>

            <div className="cont_proyect path_right">
                <h2>Tu serenata</h2>
                <Navegador href="https://tu-serenata.com">
                    <img src={web_tuserenata} alt="Tu serenata web" className="img_web" />
                </Navegador>
                <img src={cell_tuserenata} alt="Tu serenata movil" className="img_cell" />
                <p>Tu Serenata es una Aplicación móvil que mejora el sistema de reserva de una serenata, acortando el tiempo de búsqueda, y ofreciendo variedad dentro de una plataforma que está disponible las 24 horas del día.</p>
                <h4>
                    Trabajo <span>Se crea aplicación móvil y sitio web</span>
                    <br />
                    Tipo <span>Sito Web, App móvil</span>
                    <br />
                    Tecnología <span className="_tec">PHP, HTML, CSS, JavaScript, Java, Android</span>
                </h4>
            </div>

        </div>
    );
}

export default Portafolio;