import * as React from 'react';
import useMedia from 'hooks';

/* eslint eqeqeq: 0 */

const Footer = () => {
    const { t, local, setLocal } = useMedia();

    const cambiaIdioma = (e) => {
        e.preventDefault();

        setLocal(local === 'es' ? 'en' : 'es');
    }

    return (
        <>
            <button onClick={cambiaIdioma}> {local == "es" ? "English (US)" : "Español (CO)"}</button>

            <div className="pie">
                <p>© 2009 - {new Date().getFullYear()} <b>Sarguero</b> {t.msg.rights_reserved}</p>
                <i> <span>{process.env.NODE_ENV === 'production' ? t.home.version : t.home.development}</span> {process.env.REACT_APP_BUILD_VERSION}</i>
            </div>
        </>
    );
}

export default Footer;