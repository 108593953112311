import { OptionRipple } from "shared/Components";
import withRouter from "services/withRouter";
import * as Iconos from "assets/imagenes";
import api from "services/api";
import log from "services/log";
import * as React from "react";
import useMedia from "hooks";

/* eslint eqeqeq: 0 */

const Settings = (router) => {
    const { t, telegram, setTelegram } = useMedia();
    const bgColor = telegram?.themeParams?.accent_text_color;
    const { navigate } = router;

    const showPopup = (title, message) => {
        telegram.showPopup({
            title: title,
            message: message,
            buttons: [
                { id: 'yes', type: 'default', text: 'Sí' },
                { id: 'no', type: 'destructive', text: 'No' },
            ]
        }, (button_id) => {
            if (button_id === 'yes') {
                log.d('Has confirmado que sí.');
            } else if (button_id === 'no') {
                log.d('Has confirmado que no.');
            }
        });

    }

    const configFirst = async () => {
        if (telegram) {
            let _user = telegram.initDataUnsafe.user;
            let _data = await api.fetchBot({
                web_app: {
                    type: "setting",
                    setting: {
                        param: "param_01",
                        value: "Para probar"
                    },
                    from: {
                        id: _user.id,
                        is_bot: false,
                        first_name: _user.first_name,
                        username: _user.username,
                        language_code: "es"
                    },
                    chat: {
                        id: _user.id,
                        first_name: _user.first_name,
                        username: _user.username,
                        type: "private"
                    }
                }
            });
            log.d("_data", _data);
            if (_data.response == 1) {
                showPopup("Configuración", JSON.stringify(_data, null, 2));
            } else if (_data.response === -2) {
                telegram.showAlert(_data.msg, () => navigate("/"));
            } else {
                telegram.showAlert(_data.msg);
            }
        } else {
            log.e("No se ejecuta dentro del contexto de Telegram WebApp");
        }
    }

    React.useEffect(() => {
        document.title = t.titles.Ajustes;
        setTelegram(navigate);
        if (telegram) {
            telegram.BackButton.show();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="content_app">
            <div className="header">
                <h1>{t.titles.Ajustes}</h1>
                <OptionRipple bgColor={bgColor} onClick={configFirst}><Iconos.Settings />Prueba de paso de datps por api </OptionRipple>
            </div>
            <p>Esta es la configuración de la app</p>
            <div className="card">
                <OptionRipple bgColor={bgColor} onClick={() => navigate("/app/portfolio")}><Iconos.Settings />Ir a portafolio</OptionRipple>
                <OptionRipple bgColor={bgColor} onClick={() => navigate("/")}><Iconos.Settings />Ir a inicio</OptionRipple>
                <OptionRipple bgColor={bgColor}><Iconos.Settings /> Configuración de la app</OptionRipple>
            </div>
            <div className="card">
                <OptionRipple bgColor={bgColor}><Iconos.Settings /> Configuración de la app </OptionRipple>
                <OptionRipple bgColor={bgColor}><Iconos.Settings /> Configuración de la app </OptionRipple>
            </div>
        </div>
    );
}

export default withRouter(Settings);