import * as imagen from "assets/imagenes";
import * as React from 'react';
import useMedia from 'hooks';
import $ from "jquery";


const armaCambas = () => {

    $("#h1_saludo").animate({
        top: "-100vh"
    }, 1400, () => {
        $("#h1_saludo").hide();

        $("#txt_welcome p").css("transform", "initial");
        setTimeout(() => {
            $("#txt_welcome h2").css("transform", "initial");
            setTimeout(() => {
                $("#txt_welcome h1").css("transform", "initial");
            }, 400);
        }, 400);
    });
}

export const Cambas = () => {
    const { t } = useMedia();

    React.useEffect(() => {
        window.particlesJS('particles-js', {
            "particles": {
                "number": {
                    "value": 40,
                    "density": {
                        "enable": true,
                        "value_area": 200
                    }
                },
                "color": {
                    "value": "#ffffff"
                },
                "shape": {
                    "type": "circle",
                    "stroke": {
                        "width": 0,
                        "color": "#000000"
                    },
                    "polygon": {
                        "nb_sides": 5
                    },
                    "image": {
                        "src": "img/github.svg",
                        "width": 100,
                        "height": 100
                    }
                },
                "opacity": {
                    "value": 0.5,
                    "random": false,
                    "anim": {
                        "enable": false,
                        "speed": 1,
                        "opacity_min": 0.1,
                        "sync": false
                    }
                },
                "size": {
                    "value": 5,
                    "random": true,
                    "anim": {
                        "enable": false,
                        "speed": 10,
                        "size_min": 0.1,
                        "sync": false
                    }
                },
                "line_linked": {
                    "enable": true,
                    "distance": 150,
                    "color": "#ffffff",
                    "opacity": 0.4,
                    "width": 1
                },
                "move": {
                    "enable": true,
                    "speed": 2,
                    "direction": "none",
                    "random": false,
                    "straight": false,
                    "out_mode": "out",
                    "attract": {
                        "enable": false,
                        "rotateX": 600,
                        "rotateY": 1200
                    }
                }
            },
            "interactivity": {
                "detect_on": "canvas",
                "events": {
                    "onhover": {
                        "enable": true,
                        "mode": "grab"
                    },
                    "onclick": {
                        "enable": true,
                        "mode": "push"
                    },
                    "resize": true
                },
                "modes": {
                    "grab": {
                        "distance": 200,
                        "line_linked": {
                            "opacity": 1
                        }
                    },
                    "bubble": {
                        "distance": 400,
                        "size": 40,
                        "duration": 2,
                        "opacity": 8,
                        "speed": 3
                    },
                    "repulse": {
                        "distance": 200
                    },
                    "push": {
                        "particles_nb": 4
                    },
                    "remove": {
                        "particles_nb": 2
                    }
                }
            },
            "retina_detect": true,
            "config_demo": {
                "hide_card": false,
                "background_color": "#141432",
                "background_image": imagen.background_img,
                "background_position": "center center",
                "background_repeat": "no-repeat",
                "background_size": "100vw auto"
            }
        });
    }, []);

    return (<>
        <div id='home-overlay'></div>
        <div id='particles-js'></div>
        <div id="home">
            <div id='txt_welcome'>
                <h1>{t.home.dando_vida}</h1>
                <h2>{t.home.descripcion_title}</h2>
                <p>{t.home.descripcion_content}</p>
            </div>
        </div>
    </>
    );
}

const init = () => {
    setTimeout(() => {
        $("body").css("background-image", `url(${imagen.background_img})`);
        armaCambas();
    }, 1000);
}

export default init;