import withRouter from 'services/withRouter';
import * as Iconos from 'assets/imagenes';
import log from 'services/log';
import * as React from 'react';
import useMedia from 'hooks';


const AppInBot = (router) => {
    const { telegram, setTelegram } = useMedia();
    const { navigate } = router;


    const showPopup = (title, message) => {
        telegram.showPopup({
            title: title,
            message: message,
            buttons: [
                { id: 'yes', type: 'default', text: 'Sí' },
                { id: 'no', type: 'destructive', text: 'No' },
            ]
        }, (button_id) => {
            if (button_id === 'yes') {
                log.d('Has confirmado que sí.');
            } else if (button_id === 'no') {
                log.d('Has confirmado que no.');
            }
        });

    }

    const handleScanQr = () => {
        if (telegram) {
            telegram.showScanQrPopup({
                text: "Escanea un código QR para continuar",
            }, (result) => {
                telegram.closeScanQrPopup();
                if (result) {
                    showPopup("QR Code Result", result);
                    // telegram.showAlert(`QR Code Result: ${result}`);
                } else {
                    telegram.showAlert('Scanning cancelled');
                }
            });
        } else {
            log.e("No se ejecuta dentro del contexto de Telegram WebApp");
        }
    }

    React.useEffect(() => {
        document.title = "Web App in bot";
        setTelegram(navigate);
        if (telegram) {
            telegram.BackButton.hide();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="content_app">
            <div className="header">
                <div className="header_content">
                    <h1>Web App {process.env.REACT_APP_BUILD_VERSION}</h1>
                    <h2>Telegram Web App {telegram?.platform} versión {telegram?.version}</h2>
                </div>
                <div className="header_icon header_icon_left" onClick={() => navigate("/app/settings")}>
                    <Iconos.Settings />
                </div>
                <div className="header_icon header_icon_right" onClick={handleScanQr}>
                    <Iconos.ScanLine />
                </div>
            </div>

            <div className="card _padding">
                <pre><samp style={{ backgroundColor: telegram?.themeParams?.bg_color }}></samp> <span>{telegram?.themeParams?.bg_color}</span> bg_color</pre>
                <pre><samp style={{ backgroundColor: telegram?.themeParams?.text_color }}></samp> <span>{telegram?.themeParams?.text_color}</span> text_color</pre>
                <pre><samp style={{ backgroundColor: telegram?.themeParams?.hint_color }}></samp> <span>{telegram?.themeParams?.hint_color}</span> hint_color</pre>
                <pre><samp style={{ backgroundColor: telegram?.themeParams?.link_color }}></samp> <span>{telegram?.themeParams?.link_color}</span> link_color</pre>
                <pre><samp style={{ backgroundColor: telegram?.themeParams?.button_color }}></samp> <span>{telegram?.themeParams?.button_color}</span> button_color</pre>
                <pre><samp style={{ backgroundColor: telegram?.themeParams?.button_text_color }}></samp> <span>{telegram?.themeParams?.button_text_color}</span> button_text_color</pre>
                <pre><samp style={{ backgroundColor: telegram?.themeParams?.secondary_bg_color }}></samp> <span>{telegram?.themeParams?.secondary_bg_color}</span> secondary_bg_color</pre>
                <pre><samp style={{ backgroundColor: telegram?.themeParams?.header_bg_color }}></samp> <span>{telegram?.themeParams?.header_bg_color}</span> header_bg_color</pre>
                <pre><samp style={{ backgroundColor: telegram?.themeParams?.accent_text_color }}></samp> <span>{telegram?.themeParams?.accent_text_color}</span> accent_text_color</pre>
                <pre><samp style={{ backgroundColor: telegram?.themeParams?.section_bg_color }}></samp> <span>{telegram?.themeParams?.section_bg_color}</span> section_bg_color</pre>
                <pre><samp style={{ backgroundColor: telegram?.themeParams?.section_header_text_color }}></samp> <span>{telegram?.themeParams?.section_header_text_color}</span> section_header_text_color</pre>
                <pre><samp style={{ backgroundColor: telegram?.themeParams?.subtitle_text_color }}></samp> <span>{telegram?.themeParams?.subtitle_text_color}</span> subtitle_text_color</pre>
                <pre><samp style={{ backgroundColor: telegram?.themeParams?.destructive_text_color }}></samp> <span>{telegram?.themeParams?.destructive_text_color}</span> destructive_text_color</pre>
            </div>
        </div>
    );
}

export default withRouter(AppInBot);