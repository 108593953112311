import * as React from "react";
import * as Iconos from "./";


const IconTest = () => {
    const iconKeys = Object.keys(Iconos);

    React.useEffect(() => {
        document.title = "Iconos en el proyecto";
    }, []);

    return (
        <div className="content_icons">
            <h2>Iconos disponibles en el proyecto</h2>

            {iconKeys.map((iconKey, index) => (
                <>
                    {!["background_img", "logo"].includes(iconKey) &&
                        <p key={index}>
                            {/* <Iconos[iconKey] /> */}
                            {React.createElement(Iconos[iconKey], { key: index })}
                            <br />
                            <span>{iconKey}</span>
                        </p>}
                </>
            ))}
        </div>
    )
}

export default IconTest;