import { ButtonRipple } from "shared/Components";
import withRouter from "services/withRouter";
import { alertaRemove } from "shared/Alert";
import * as Iconos from "assets/imagenes";
import * as React from "react";
import useMedia from "hooks";


const RequestProject = (router) => {
    const { t, telegram, setTelegram } = useMedia();
    const [showLoading, setShowLoading] = React.useState(false);
    const bgColor = telegram?.themeParams?.accent_text_color;
    const [subject, setSubject] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [email, setEmail] = React.useState("");
    const form_ref = React.useRef(null);
    const { navigate } = router;

    const validate = () => {
        setShowLoading(true);
    }

    React.useEffect(() => {
        document.title = t.titles.request_project;
        setTelegram(navigate);
        if (telegram) {
            telegram.BackButton.show();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="content_app">
            <div className="header">
                <h1>{t.titles.request_project}</h1>
            </div>
            <div className="card cont_contact">
                <form ref={form_ref}>

                    <label>{t.forms.contact_name}</label>
                    <div>
                        <input type="text" name="subject" maxLength="150" value={subject}
                            onChange={e => { alertaRemove(form_ref.current.subject); setSubject(e.target.value) }} />
                    </div>

                    <label>{t.forms.phone_number}</label>
                    <div>
                        <input type="text" name="subject" maxLength="150" value={subject}
                            onChange={e => { alertaRemove(form_ref.current.subject); setSubject(e.target.value) }} />
                    </div>

                    <label>{t.forms.email}</label>
                    <div>
                        <input type="text" name="mail" maxLength="75" value={email}
                            onChange={e => { alertaRemove(form_ref.current.mail); setEmail(e.target.value) }} />
                    </div>

                    <label>{t.forms.project_name}</label>
                    <div>
                        <input type="text" name="subject" maxLength="150" value={subject}
                            onChange={e => { alertaRemove(form_ref.current.subject); setSubject(e.target.value) }} />
                    </div>

                    <label>{t.forms.project_description}</label>
                    <div>
                        <textarea name="message" cols="30" rows="5" value={message}
                            onChange={e => { alertaRemove(form_ref.current.message); setMessage(e.target.value) }}></textarea>
                    </div>

                    <div id="btn_send" style={{ backgroundImage: `url(${Iconos.background_img})` }}>
                        {showLoading ?
                            <ButtonRipple bgColor={bgColor}><Iconos.Loading /></ButtonRipple>
                            :
                            <ButtonRipple bgColor={bgColor} onClick={validate}>{t.forms.send}</ButtonRipple>
                        }
                    </div>
                </form>
            </div>
        </div>
    );
}

export default withRouter(RequestProject);