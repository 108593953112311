import * as React from 'react';
import useMedia from 'hooks';


const Curriculum = () => {
    const { t } = useMedia();

    React.useEffect(() => {
        document.title = t.menu.curriculo;
    }, [t]);

    return (
        <div className="cont_principal">
            <section>

                <h1>{t.menu.curriculo}</h1>

                <div className="description">
                    <h2>{t.msg.site_in_buld_tile}</h2>
                    <p>{t.msg.site_in_buld_content} <a href="mailto:desarrollo@sarguero.com">desarrollo@sarguero.com</a></p>
                </div>
            </section>
        </div>
    );
}

export default Curriculum;