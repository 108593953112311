import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Confirm } from "shared/Alert";
import * as Imagen from "assets/imagenes";
import useMedia from "hooks";

/* eslint eqeqeq: 0 */


const Header = () => {
    const { t } = useMedia();
    const [wallet, setWallet] = React.useState(null);
    const navigate = useNavigate();

    const connectWallet = async () => {
        const { ethereum } = window;

        if (ethereum) {
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            const chainId = await ethereum.request({ method: 'eth_chainId' });

            // console.log('accounts', accounts);
            // console.log('chainId', chainId);

            if (chainId == '0x1') {
                console.log("Connected to Mainnet");
            }

            if (wallet == null) {
                let wallet = accounts[0];

                if (wallet === "0x0def17caa07e4650d3bcef09af8d983392b64b0e") {
                    wallet = wallet.substring(0, 6) + '...' + wallet.substring(wallet.length - 4, wallet.length);
                    setWallet(wallet);
                } else {
                    setWallet(null);
                    Alert("La wallet (" + wallet.substring(0, 8) + '...' + wallet.substring(wallet.length - 6, wallet.length) + ") no esta permitida", "alert");
                }
            } else {
                Confirm("¿Desconectar wallet?", response => {
                    if (response) {
                        setWallet(null);
                    }
                });
            }

        } else {
            Alert("Porfavor instala una wallet", "alert");
        }
    }

    return (
        <header role="banner">
            <img className="logo" src={Imagen.logo} alt="logo principal" onClick={() => navigate("/")} />
            <h2 onClick={() => navigate("/")}>Sarguero</h2>

            <div className="cont_menu">

                <a href="#home" className="menu_button" ><Imagen.Home /><samp>{t.menu.home}</samp></a>
                {/* <Link to="/curriculum" id="btn_curriculum" className="menu_button" ><Imagen.Curriculum /><samp>{t.menu.curriculo}</samp></Link> */}
                <a href="#portafolio" className="menu_button" ><Imagen.Portafolio /><samp>{t.menu.portafolio}</samp></a>
                <a href="#contacto" className="menu_button" ><Imagen.AirPlane /><samp>{t.menu.contacto}</samp></a>
                <button className={wallet ? "menu_button _resaltado" : "menu_button _wallet"} onClick={connectWallet}><Imagen.Wallet />
                    {wallet ? <samp>{wallet}</samp> : <samp>Conectar</samp>}
                </button>

            </div>
        </header>
    );
}

export default Header;