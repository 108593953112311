import translations from "assets/translation";
import * as imagen from "assets/imagenes";
import * as React from "react";
import log from "./log";

const ContextMedia = React.createContext();

export function MediaProvider({ children }) {
    const ln = (window.navigator.language || navigator.browserLanguage).split("-")[0];
    const [local, setLocalText] = React.useState(ln ?? "en");
    const [t, setLocalFIle] = React.useState(translations[local]);
    const [telegram, configTelegram] = React.useState();


    const setLocal = (loc) => {
        setLocalText(loc);
        setLocalFIle(translations[loc]);
    }

    const applyTheme = (params) => {
        params.text_color ??= '#afafaf';
        params.bg_color ??= '#212121';
        params.secondary_bg_color ??= '#0f0f0f';

        document.body.style.color = params.text_color;
        document.body.style.backgroundImage = `url(${imagen.background_img})`;

        if (params.secondary_bg_color) {
            document.body.style.backgroundColor = params.secondary_bg_color;
            document.querySelectorAll('.content_app').forEach(element => {
                element.style.backgroundColor = `${params.secondary_bg_color}b3`;
            });
            document.querySelectorAll(`
                    .header, 
                    .header_icon, 
                    .card,
                    .cont_portafolio .cont_proyect,
                    .cont_error
                `).forEach(element => {
                element.style.backgroundColor = params.bg_color;
            });
            document.querySelectorAll(`
                    .header_icon svg, 
                    .header_content h2,
                    .content_app h3,
                    .content_app h3 svg,
                    .card,
                    .cont_portafolio .cont_proyect p,
                    .cont_portafolio .cont_proyect h3 span,
                    .cont_portafolio .cont_proyect h4 span
                `).forEach(element => {
                element.style.color = params.text_color;
            });
            document.querySelectorAll(`.cont_contact input[type="text"], .cont_contact textarea`).forEach(element => {
                element.style.color = params.text_color;
            });
        }

        if (params.hint_color) {
            document.querySelectorAll('.hint_text').forEach(element => {
                element.style.color = params.hint_color;
            });
        }

        if (params.link_color) {
            document.querySelectorAll('a').forEach(element => {
                element.style.color = params.link_color;
            });
        }

        if (params.button_color) {
            document.querySelectorAll('button, .button_ripple').forEach(element => {
                element.style.backgroundColor = `${params.button_color}b3`;
                // element.style.backgroundColor = params.button_color;
            });
        }

        if (params.button_text_color) {
            document.querySelectorAll('button, .button_ripple').forEach(element => {
                element.style.color = params.button_text_color;
            });
        }
    }

    const setTelegram = (navigate) => {
        if (telegram) {
            applyTheme(telegram.themeParams);
        } else {
            const script = document.createElement('script');
            script.src = 'https://telegram.org/js/telegram-web-app.js';
            document.body.appendChild(script);
            script.onload = () => {
                const _telegram = window.Telegram.WebApp;
                log.i("WebApp", _telegram);
                log.i("initDataUnsafe", _telegram.initDataUnsafe);
                log.i("version", _telegram.version);
                // log.i("init data", _telegram.initData);

                // Expande a la pantalla completa
                _telegram.expand();

                // Configurar el BackButton
                if (parseFloat(_telegram.version) >= 6.1) {
                    _telegram.BackButton.hide();
                    _telegram.BackButton.onClick(() => {
                        navigate(-1);
                    });
                }

                if (_telegram.platform === "unknown") {
                    log.e("No se ejecuta dentro del contexto de Telegram WebApp");
                } else {
                    log.i("params", _telegram.themeParams);
                    configTelegram(_telegram);
                }
                applyTheme(_telegram.themeParams);
            };
        }
    }

    return <ContextMedia.Provider value={{ t, local, setLocal, telegram, setTelegram }}>
        {children}
    </ContextMedia.Provider>
}

export { ContextMedia };