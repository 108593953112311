import * as React from 'react';
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import { MediaProvider } from './Contexts';
import ScrollToTop from './ScrollToTop';
import "../assets/styles/app.scss";

import Header from "shared/header";
import Footer from "shared/footer";
import IconTest from 'assets/imagenes/IconTest';
import { AppNoMatch, NoMatch } from 'shared/NoMatch';

import Home from '../views/Home';
import Curriculum from '../views/Curriculum';
import Portafolio from '../views/Portafolio';
import Contacto from '../views/Contacto';

import AppInBot from '../views/AppInBot';
import Settings from '../views/AppInBot/components/Settings';
import Portfolio from '../views/AppInBot/components/Portfolio';
import RequestProject from '../views/AppInBot/components/RequestProject';


const AppRoutes = () => {

    const WebRoutes = () => {
        return (<>
            <Header />
            <main className="wrapper">
                <Outlet />
            </main>
            <footer>
                <Footer />
                <div id="modal"></div>
            </footer>
        </>);
    }
    
    const AppRoutes = () => {
        return (<>
            <main className="wrapper">
                <Outlet />
            </main>
            <footer>
                <div id="modal"></div>
            </footer>
        </>);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    React.useEffect(() => {
        document.onselectstart = () => false;

        if (typeof android !== "undefined") {
            document.onselectstart = () => false;
        } else {
            if (process.env.NODE_ENV === 'production') {
                document.addEventListener("contextmenu", handleContextMenu);

                return () => {
                    document.removeEventListener("contextmenu", handleContextMenu);
                }
            }
        }
    }, []);

    return (
        <BrowserRouter>
            <ScrollToTop>
                <MediaProvider>
                    <Routes>
                        <Route path="/" element={<WebRoutes />}>
                            <Route path="/" element={<Home />} />
                            <Route path="/curriculum" element={<Curriculum />} />
                            <Route path="/portafolio" element={<Portafolio />} />
                            <Route path="/contacto" element={<Contacto />} />
                            <Route path="/icons-in-proyect" element={<IconTest />} />
                            <Route path="*" element={<NoMatch />} />
                        </Route>
                        <Route path="/app" element={<AppRoutes />}>
                            <Route path="/app" element={<AppInBot />} />
                            <Route path="/app/settings" element={<Settings />} />
                            <Route path="/app/portfolio" element={<Portfolio />} />
                            <Route path="/app/request-project" element={<RequestProject />} />
                            <Route path="*" element={<AppNoMatch />} />
                        </Route>
                    </Routes>
                </MediaProvider>
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default AppRoutes;
