const WEB_API = process.env.REACT_APP_WEB_API;

/* eslint import/no-anonymous-default-export: 0 */

class API {

    /**
     * Verifica el estado de la respuesta en la consulta 
     * @param {Response} response: La respuesta generada en la consulta 
     */
    verificaEstado(response) {
        switch (response.status) {
            case 200:
            case 210:
                //console.log('Conexión corecta', response.status);
                break;
            case 401:
            case 403:
            case 422:
                console.log('Error', response.status);
                break;
            case 404:
                throw new Error('404 - No se encuentra el recurso!');
            case 500:
            case 501:
            case 502:
                console.log('Error', response.status);
                // throw new Error('500 - Ocurrio un error en la sintaxis!');
                break;
            case 503:
                throw new Error('503 - Servicio no disponible!');
            default:
                throw new Error(`(${response.status}) es un código inesperado, puedes reportarlo al correo "desarrollo@sarguero.com" para revisarlo mas a fondo`);
        }
    }

    /**
     * Hace una consulta al servidor 
     * 
     * @param {{url: string, data: JSON}} params: json con { `url`: string, `data`: json }
     * @return {Promise<{response?: number, msg?: string, data?: JSON}>}: `json` con los datos solicitados 
     */
    async fetchJson(params) {
        let datos;

        await fetch(WEB_API + params.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(params.data)
        })
            .then(response => {
                this.verificaEstado(response);
                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                console.log('Ocurrio un error:', error);
                datos = { response: -1, msg: error.toString() };
            });

        return datos;
    }

    /**
     * Hace una consulta al bot de telegram
     * 
     * @param {JSON} params: json con los datos a enviar
     * @return {Promise<{response?: number, msg?: string, data?: JSON}>}: `json` con los datos solicitados 
     */
    async fetchBot(data) {
        let datos;

        await fetch(process.env.REACT_APP_BOT_TELEGRAM, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                this.verificaEstado(response);
                return response.json();
            })
            .then(data => {
                datos = data;
            })
            .catch((error) => {
                console.log('Ocurrio un error:', error);
                datos = { response: -1, msg: error.toString() };
            });

        return datos;
    }
}

export default new API();