import * as React from 'react';

export const OptionRipple = ({ children, bgColor, onClick = () => { } }) => {
    const [touchSupported, setTouchSupported] = React.useState(false);
    const [ripples, setRipples] = React.useState([]);


    const createRipple = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const button = event.currentTarget;
        const rect = button.getBoundingClientRect();
        const size = Math.max(rect.width, rect.height);
        let x, y;

        if (event.type === 'mousedown') {
            x = event.clientX - rect.left - size / 2;
            y = event.clientY - rect.top - size / 2;
        } else if (event.type === 'touchstart') {
            const touch = event.touches[0];
            x = touch.clientX - rect.left - size / 2;
            y = touch.clientY - rect.top - size / 2;
        }

        const newRipple = { x, y, size };

        setRipples((prevRipples) => [...prevRipples, newRipple]);
    }

    React.useEffect(() => {
        setTouchSupported('ontouchstart' in window || navigator.maxTouchPoints > 0);
        console.log("touchSupported", 'ontouchstart' in window || navigator.maxTouchPoints > 0);
    }, []);

    return (<h3 onMouseDown={!touchSupported ? createRipple : null} onTouchStart={createRipple} onClick={onClick}>
        <span className="text">{children}</span>
        {ripples.map((ripple, index) => (
            <span key={index}
                className="ripple"
                style={{
                    left: ripple.x,
                    top: ripple.y,
                    width: ripple.size,
                    height: ripple.size,
                    // backgroundColor: `${bgColor}66`,
                    backgroundColor: `${bgColor}80`,
                    // backgroundColor: `${bgColor}99`,
                }}
            />
        ))}
    </h3>)
}

export const ButtonRipple = ({ children, bgColor = "rgba(20, 20, 50, 0.5)", onClick = () => { } }) => {
    const [touchSupported, setTouchSupported] = React.useState(false);
    const [ripples, setRipples] = React.useState([]);

    const createRipple = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const button = event.currentTarget;
        const rect = button.getBoundingClientRect();
        const size = Math.max(rect.width, rect.height);
        let x, y;

        if (event.type === 'mousedown') {
            x = event.clientX - rect.left - size / 2;
            y = event.clientY - rect.top - size / 2;
        } else if (event.type === 'touchstart') {
            const touch = event.touches[0];
            x = touch.clientX - rect.left - size / 2;
            y = touch.clientY - rect.top - size / 2;
        }

        const idRipple = Symbol();
        const newRipple = { x, y, size, idRipple };

        setRipples((prevRipples) => [...prevRipples, newRipple]);

        // setTimeout(() => {
        //     setRipples((prevRipples) => prevRipples.filter((ripple) => ripple.idRipple !== idRipple));
        // }, 1200);
    }

    React.useEffect(() => {
        setTouchSupported('ontouchstart' in window || navigator.maxTouchPoints > 0);
        console.log("touchSupported", 'ontouchstart' in window || navigator.maxTouchPoints > 0);
    }, []);

    return (<button type="button"
        className="button_ripple"
        onMouseDown={!touchSupported ? createRipple : null}
        onTouchStart={createRipple}
        onClick={onClick}>
        <span className="text">{children}</span>
        {ripples.map((ripple, index) => (
            <span key={index}
                className="ripple"
                style={{
                    left: ripple.x,
                    top: ripple.y,
                    width: ripple.size,
                    height: ripple.size,
                    backgroundColor: bgColor,
                }}
            />
        ))}
    </button>)
}