import * as React from "react";
import { Alert, alerta, alertArea, alertaRemove } from "shared/Alert";
import { ButtonRipple } from "shared/Components";
import { useNavigate } from "react-router-dom";
import * as Icon from "assets/imagenes";
import api from "services/api";
import useMedia from "hooks";

/* eslint eqeqeq: 0 */
/* eslint no-useless-escape: 0 */

const Contacto = () => {
    const { t } = useMedia();
    const [showLoading, setShowLoading] = React.useState(false);
    const [subject, setSubject] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [email, setEmail] = React.useState("");
    const form_ref = React.useRef(null);
    const navigate = useNavigate();


    const validate = () => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        alertaRemove(form_ref.current.mail);
        alertaRemove(form_ref.current.subject);
        alertaRemove(form_ref.current.message);

        if (email == null || email.length < 1) {
            alerta(form_ref.current.mail, t.msg.mail_require);
            form_ref.current.mail.focus();
            return false;
        }

        if (!regex.test(String(email).toLowerCase())) {
            alerta(form_ref.current.mail, t.msg.mail_format_error);
            form_ref.current.mail.focus();
            return false;
        }

        if (subject == null || subject.length < 1) {
            alerta(form_ref.current.subject, t.msg.subject_require);
            form_ref.current.subject.focus();
            return false;
        }

        if (message == null || message.length < 1) {
            alertArea(form_ref.current.message, t.msg.message_require);
            form_ref.current.message.focus();
            return false;
        }

        sendMail();
    }

    const sendMail = async () => {
        setShowLoading(true);

        let _data = await api.fetchBot({
            web_app: {
                type: "contact",
                contact: {
                    domain: "sarguero.com",
                    email: email,
                    email_destino: "rodrigo.saenz@sarguero.com",
                    subject: subject,
                    message: message
                },
                from: {
                    id: 1534351519,
                    is_bot: false,
                    first_name: "sarguero",
                    username: "sarguero",
                    language_code: "es"
                },
                chat: {
                    id: 1534351519,
                    first_name: "sarguero",
                    username: "sarguero",
                    type: "private"
                }
            }
        });

        if (_data.response == 1) {
            Alert("¡Gracias por contactarnos! \n\nTe responderemos en el menor tiempo posible.", "ok", () => {
                setEmail("");
                setSubject("");
                setMessage("");
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/"));
        } else {
            Alert(_data.msg, "warning");
        }

        setShowLoading(false);
    }

    return (
        <div className="cont_contact" id="contacto">
            <h1>{t.menu.contacto}</h1>

            <p>{t.forms.contact} <a href="mailto:desarrollo@sarguero.com">desarrollo@sarguero.com</a> {t.forms.contact2}</p>

            <hr />

            <form ref={form_ref}>

                <label>{t.forms.email}</label>
                <div>
                    <input type="text" name="mail" maxLength="75" value={email}
                        onChange={e => { alertaRemove(form_ref.current.mail); setEmail(e.target.value) }} />
                </div>

                <label>{t.forms.subject}</label>
                <div>
                    <input type="text" name="subject" maxLength="150" value={subject}
                        onChange={e => { alertaRemove(form_ref.current.subject); setSubject(e.target.value) }} />
                </div>

                <label>{t.forms.message}</label>
                <div>
                    <textarea name="message" cols="30" rows="5" value={message}
                        onChange={e => { alertaRemove(form_ref.current.message); setMessage(e.target.value) }}></textarea>
                </div>

                <div id="btn_send" style={{ backgroundImage: `url(${Icon.background_img})` }}>
                    {showLoading ?
                        <ButtonRipple><Icon.Loading /></ButtonRipple>
                        :
                        <ButtonRipple onClick={validate}>{t.forms.send}</ButtonRipple>
                    }
                </div>
            </form>
        </div>
    );
}

export default Contacto;