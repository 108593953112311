import withRouter from "services/withRouter";
import { Link } from "react-router-dom";
import * as React from "react";
import useMedia from "hooks";


const NoMatch = () => {
    const { t } = useMedia();

    React.useEffect(() => {
        document.title = t.titles.page_not_found;
    }, [t]);

    return (
        <div className="cont_error">
            <p><span>Error 404</span>
                <b>{t.msg.we_feel}</b> {t.msg.we_feel_content} <Link to="/">{t.msg.here}.</Link>
            </p>
            <img src="/static/img/employee_angry.svg" alt="imagen de error" />
        </div>
    );
}

const RouterNoMatch = (router) => {
    const { t, telegram, setTelegram } = useMedia();
    const { navigate } = router;

    React.useEffect(() => {
        setTelegram(navigate);
        if (telegram) {
            telegram.BackButton.show();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="content_app">
            <div className="header">
                <h1>{t.titles.page_not_found}</h1>
            </div>
            <NoMatch />
        </div>
    );
}

const AppNoMatch = withRouter(RouterNoMatch);

export { NoMatch, AppNoMatch };
